<script>
import GlobalVue from '@root/helper/Global.vue';
import Gen from '@helper/Gen';
import BOGen from '@helper/BOGen';
export default {
  name: "NavBar",
  extends: GlobalVue,
  data(){
    return{
      notif:{}
    }    
  },
  mounted(){
    window.nav = this
  },
  methods:{
    hideLogo(){
      if($('#lerge-logo').hasClass( "hide-menu" )){
        $('#lerge-logo').removeClass( "hide-menu" )
        $('#small-logo').addClass( "hide-menu" )
      }
      else{
        $('#lerge-logo').addClass( "hide-menu" )
        $('#small-logo').removeClass( "hide-menu" )
      }
    }
  }
}
</script>

<template>
    <header class="topbar">
      <nav class="navbar top-navbar navbar-expand-md navbar-dark">
          <!-- ============================================================== -->
          <!-- Logo -->
          <!-- ============================================================== -->
          <div class="navbar-header pl-0 text-center">
              <a class="navbar-brand" >
                  <img :src="assets('bo_images','logo-dima.png')" alt="homepage" id="lerge-logo" class="dark-logo" />
                  <img :src="assets('bo_images','logo-icon.png')" alt="homepage" id="small-logo" class="dark-logo hide-menu" />
                 </a>
          </div>
          <!-- ============================================================== -->
          <!-- End Logo -->
          <!-- ============================================================== -->
          <div class="navbar-collapse">
              <!-- ============================================================== -->
              <!-- toggle and nav items -->
              <!-- ============================================================== -->
              <ul class="navbar-nav mr-auto">
                  <!-- This is  -->
                  <li class="nav-item" @click="hideLogo"> <a class="nav-link nav-toggler d-block d-md-none waves-effect waves-dark" href="javascript:void(0)"><i class="ti-menu"></i></a> </li>
                  <li class="nav-item" @click="hideLogo"> <a class="nav-link sidebartoggler d-none d-lg-block d-md-block waves-effect waves-dark" href="javascript:void(0)"><i class="icon-menu"></i></a> </li>
                  <!-- ============================================================== -->
                  <!-- Search -->
                  <!-- ============================================================== -->
                  <!-- <li class="nav-item">
                      <form class="app-search d-none d-md-block d-lg-block">
                          <input type="text" class="form-control" placeholder="Search & enter">
                      </form>
                  </li> -->
              </ul>
              <!-- ============================================================== -->
              <!-- User profile and search -->
              <!-- ============================================================== -->
              <ul class="navbar-nav my-lg-0">
                  <!-- ============================================================== -->
                  <!-- Comment -->
                  <!-- ============================================================== -->
                
                 
                  <li class="nav-item"> <a class="nav-link" href="javascript:;">Backoffice Ver 6.0</a></li>
              </ul>
          </div>
      </nav>
  </header>
</template>
